import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-orange-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-orange-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-1 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">DrinkRoyale</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                DrinkRoyale is a cross-platform game released in 2024. Our goal was to raise the standard in a neglected and undervalued market: adult party games. By emphasizing ease of access, meticulous content curation, and leveraging synchronized user devices, DrinkRoyale has emerged as a "fast-casual" drinking game like no other. This solo-developer indie game has quickly caught fire on social media, and we intend to keep our foot on the gas. Our team is committed to weekly content refinement cycles to ensure our best content is highlighted for new users, our worst content is removed, and new content is continuously introduced to keep the experience fresh for returning users.
                                </p>
                                <div className="flex justify-center my-4">
                                    <div className="text-white bg-orange-900 hover:bg-orange-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                       <a  href="https://drinkroyale.com">Visit</a> 
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </div>
                                </div>
                            </div>
                        </div>                 
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;