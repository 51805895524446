import React from 'react';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Intro from './components/Intro';
import Portfolio from './components/Portfolio';
import Legal from './components/Legal';

const Home = () => {
    return (
        <>
            <Hero />
            <Intro />
            <Portfolio />
            <Legal/>
            <Footer />
        </>

    )
}

export default Home;

