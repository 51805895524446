import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-orange-900" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-orange-900" smooth to="/#portfolio">
                DrinkRoyale
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-orange-900" smooth to="/#Legal">
                Legal
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-orange-900" to="/contact">
                Contact Us
            </HashLink>
        </>
    )
}

export default NavLinks;
