import React, { useState } from 'react';
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer';
import {useDocTitle} from './components/CustomHook';
import axios from 'axios';
import Notiflix from 'notiflix';

const Contact = () => {
    useDocTitle('Honey Roasted Games')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type':  'multipart/form-data'
            }
        })
        .then(function (response) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            clearInput()
            //handle success
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay',
            );
        })
        .catch(function (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            //handle error
            const { response } = error;
            if(response.status === 500) {
                Notiflix.Report.failure(
                    'An error occurred',
                    response.data.message,
                    'Okay',
                );
            }
            if(response.data.errors !== null) {
                setErrors(response.data.errors)
            }
            
        });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div className="flex flex-col">
                            <h1 className="font-bold text-center lg:text-left text-orange-900 uppercase text-4xl mb-8">Send us a message</h1>

                            <div className="w-full bg-orange-900 rounded-2xl p-8 text-white lg:w-4/6 lg:p-6">
                                <div className="mb-4">
                                    <i className="fas fa-map-marker-alt pr-2" />
                                    <h2 className="text-2xl inline">Office Address</h2>
                                    <p className="text-gray-400 ml-8">4030 Wake Forest Road<br />Ste. 349<br />Raleigh, NC</p>
                                </div>

                                <div className="mb-4">
                                    <i className="fas fa-phone-alt pr-2" />
                                    <h2 className="text-2xl inline">Call Us</h2>
                                    <p className="text-gray-400 ml-8">Tel: (262) 832 4293</p>
                                </div>

                                <div className="mb-4">
                                    <h2 className="text-2xl">Send an E-mail</h2>
                                    <p className="text-gray-400">honeyroastedgames@gmail.com</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;
