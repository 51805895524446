import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='Legal'>
                <h2 className="my-2 text-center text-3xl text-orange-900 uppercase font-bold">Legal</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-orange-900 mb-8'></div>
                </div>

                <div className="px-4  text-center" data-aos="fade-down" data-aos-delay="600">
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                <a href="/terms of service 6_4_24.pdf" target="_blank" rel="noopener noreferrer">
                                  Review Terms of Service [pdf]
                                </a>
                                </p>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                <a href="/privacy 8_20_24.pdf" target="_blank" rel="noopener noreferrer">
                                  Review Privacy Policy [pdf]
                                </a>
                                </p>
                </div>
            </div>
        </>
    )
}

export default Portfolio;